<template>
    <div class="basic-it-content">
        <div v-if="config.image" class="it-image-container">
            <div 
                class="it-image-content"
                v-if="config.isBackground"
                :style="'background-image: url('+ getImg(config.image) +')'"
            />
            <div 
                class="it-image-content"
                v-else
            >
                <img v-lazy="getImg(config.image)" alt="">
            </div>
        </div>
        <div class="it-text-content">
            <inline-edit element="h2" :target="config.title"/>
            <inline-edit element="p"  :target="config.text"/>
            <div v-if="config.button" class="it-button-content">
                <inline-edit :link="config.button.link" element="a" :target="config.button.text"/>
            </div>
        </div>
        
    </div>
</template>

<script>

    import * as imgManager from "@/store/imgManager";

    export default {
        props: ["config"],
        methods:{
            getImg(image){
                let img = imgManager.getImage(image);
                if(img){
                    if(img.path){
                        return img.path;
                    }else{
                        return img.file;
                    }
                }
            }
        },
        mounted(){
            if(!this.config.image){
                this.config["image"] = ""
            }
            if(!this.config.title){
                this.config["title"] = {value:""}
            }
            if(!this.config.text){
                this.config["text"] = {value:""}
            }
            if(typeof this.config.text === 'string'){
                this.config["text"] = {value: this.config.text}
            }
            if(typeof this.config.title === 'string'){
                this.config["title"] = {value: this.config.title}
            }
            if(this.config.button){
                if(typeof this.config.button.text === 'string'){
                    this.config.button["text"] = {value: this.config.button.text}
                }
            }
        }
    }
      
</script>